export const calculateDistance = (point1, point2) => {
    // Example implementation using the Haversine formula
    // Convert lat/long from degrees to radians
    var rad = function(x) { return x * Math.PI / 180; };

    var R = 6371; // Earth’s mean radius in km
    var dLat = rad(point2.lat - point1.lat);
    var dLong = rad(point2.lng - point1.lng);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(point1.lat)) * Math.cos(rad(point2.lat)) * 
        Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var distance = R * c;
    
    return distance; // returns the distance in kilometers or miles
};
