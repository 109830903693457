import Cloudmap from './components/Cloudmap';
import IntroOverlay from './components/IntroOverlay';
import 'font-awesome/css/font-awesome.min.css';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { logEvent } from "firebase/analytics";
import { useEffect } from 'react';
import { MapProvider } from './components/elements/mapContext';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB7gk5m676iSJtcNvqdfOL3XYrljQybgEY",
  authDomain: "remembertherain-c3010.firebaseapp.com",
  projectId: "remembertherain-c3010",
  storageBucket: "remembertherain-c3010.appspot.com",
  messagingSenderId: "232546435884",
  appId: "1:232546435884:web:f4872f9ecf30100ee86289",
  measurementId: "G-MCDPLSVY6E"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {

  useEffect(() => {
    // Track Mouse Movement
    const trackMouseMovement = (e) => {
      // Send mouse coordinates to Firebase (or process them in another way)
      logEvent(analytics, 'mousemove', { 
        x: e.clientX,
        y: e.clientY
      });
    };

    // Track Clicks
    const trackClick = (e) => {
      logEvent(analytics, 'click', { 
        x: e.clientX,
        y: e.clientY,
        element: e.target.tagName // This will log the type of element clicked (e.g., "BUTTON", "A", etc.)
      });
    };

    // Track Scrolls
    const trackScroll = () => {
      logEvent(analytics, 'scroll', { 
        scrollPosition: window.scrollY
      });
    };

    // Attach event listeners
    document.addEventListener('mousemove', trackMouseMovement);
    document.addEventListener('click', trackClick);
    window.addEventListener('scroll', trackScroll);

    // Clean up the event listeners when the component is unmounted
    return () => {
      document.removeEventListener('mousemove', trackMouseMovement);
      document.removeEventListener('click', trackClick);
      window.removeEventListener('scroll', trackScroll);
    };
  }, []);

  logEvent(analytics, 'Home Page');

  return (
    <>
    <MapProvider>
      <IntroOverlay />
      <Cloudmap />
    </MapProvider>
    </>
  );
}

export default App;
