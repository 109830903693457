import { createContext, useContext, useState } from 'react';

const MapContext = createContext();

export function MapProvider({ children }) {
    const [randomLocation, setRandomLocation] = useState(null);

    return (
        <MapContext.Provider value={{ randomLocation, setRandomLocation }}>
            {children}
        </MapContext.Provider>
    );
}

export function useMapContext() {
    const context = useContext(MapContext);
    if (!context) {
        throw new Error('useMapContext must be used within a MapProvider');
    }
    return context;
}
