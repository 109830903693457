import React, { useState, useCallback, useEffect, useMemo } from "react";
import "../styles/CloudText.css";

const computeMaxRandomWidth = (zoom, minZoom, maxZoom, minWidth, maxWidth) => {
  const t = (zoom - minZoom) / (maxZoom - minZoom);
  return minWidth + t * (maxWidth - minWidth);
};

export const LocationNames = React.memo(({ names, zoom, selectedVictim, lat, lng, zoomedIn }) => {
  const minRandomWidth = 200;
  const maxRandomWidth = 600;
  const minZoom = 1;
  const maxZoom = 20;

  const computedMaxWidth = useMemo(() => {
    return computeMaxRandomWidth(zoom, minZoom, maxZoom, minRandomWidth, maxRandomWidth);
  }, [zoom]);

  const maxWidth = computedMaxWidth;  // Use the computed value directly, no conditional logic

  const fontZoomLevel = useMemo(() => {
    if (zoom < 2) {
      return 8;
    } else if (zoom > 6) {
      return zoom * 2;
    } else {
      return zoom + 20;
    }
  }, [zoom]);

  const [highlight, setHighlight] = useState(false);

  const isSelected = useCallback(() => {
    if (
      selectedVictim &&
      selectedVictim.lat === lat &&
      selectedVictim.lng === lng &&
      zoomedIn
    ) {
      setHighlight(true);
      setTimeout(() => {
        setHighlight(false);
      }, 1000);
      return true;
    }
    return false;
  }, [selectedVictim, lat, lng, zoomedIn]);

  useEffect(() => {
    isSelected();
  }, [isSelected, zoomedIn]);

  const animationLevel = useMemo(() => {
    const baseDuration = zoom > 8 ? 240 : 30;
    const moveDuration = `${baseDuration + (Math.random() - 0.5) * 10}s`;  // Introduce randomness

    if (zoom > 8) {
      return `moveCloud ${moveDuration} linear infinite`;
    } else {
      return `moveCloud ${moveDuration} ease-in-out infinite, cloudEffect 60s linear infinite, dance 15s infinite`;
    }
}, [zoom]);

  const contrastLevel = zoomedIn ? "150%" : "100%";
  const blurLevel = zoom >= 15 ? 0 : Math.max(10 - zoom, 0);
  const zoomedInClass = zoom >= 10 ? "zoomed-in" : "";

  const computeOffset = (index) => {
      const baseOffset = 0;
      const randomOffsetVertical = (Math.random() - 0.5) * 4 * baseOffset;
      const randomOffsetHorizontal = (Math.random() - 0.5) * 4 * baseOffset;
      return {
        vertical: index * baseOffset + randomOffsetVertical,
        horizontal: randomOffsetHorizontal
      };
  };

  return (
    <div
      className={`names-container ${zoomedInClass} zoom${Math.floor(zoom)}`}
      style={{
        minWidth: "40%",
        maxWidth: `${maxWidth}px`,
        fontSize: `${fontZoomLevel}px`,
        textAlign: "center",
        filter: `blur(${blurLevel}px) contrast(${contrastLevel})`,
        transition: "filter 0.3s ease-in-out, maxWidth 0.5s ease-in-out",
        willChange: "filter, maxWidth, fontSize"
      }}
    >
      {names &&
        names.map((name, index) => (
          <React.Fragment key={index}>
            <span
              className={`name ${highlight ? "pulse" : ""}`}
              style={{
                animation: animationLevel,
                willChange: "animation",
                transform: `translateY(${computeOffset(index).vertical}px)`,
                '--random-offset': `${(Math.random() - 0.5) * 10}px`,
                '--z-index': `${Math.floor(Math.random() * 10)}`
              }}
            >
              {name}
            </span>
          </React.Fragment>
        ))}
    </div>
  );
});

export default React.memo(LocationNames);