import React, { useState, useCallback, useEffect, useMemo } from "react";
import { GoogleMap, LoadScript, OverlayView } from "@react-google-maps/api";
import { debounce } from "lodash";

import Legend from "./elements/Legend";
import LocationNames from "./elements/LocationNames";
import useGoogleMapListener from "./elements/useGoogleMapListener";
import { fetchData } from "./elements/DataBase";
import { clusterNames, MAX_VISIBLE_NAMES } from "./elements/clusterNames";
import { shouldRenderOverlay } from "./elements/shouldRenderOverlay";
import { useMapContext } from "./elements/mapContext";
import { DATA_CHUNK_SIZE, dragDebounced, MIN_ZOOM_LEVEL, bounds, containerStyle, center } from "./elements/DefaultSettings";

import "./styles/CloudText.css";

const setVHProperty = () => {
  const vh = window.visualViewport.height * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

function useWindowResize(callback) {
  useEffect(() => {
    const handleResize = () => {
      callback();
    };
    window.visualViewport.addEventListener("resize", handleResize);
    return () => {
      window.visualViewport.removeEventListener("resize", handleResize);
    };
  }, [callback]);
}


function Cloudmap() {
  useWindowResize(setVHProperty);

  const [incidentCount, setIncidentCount] = useState(0);
  const [victimCount, setVictimCount] = useState(0);
  const [dataBase, setDataBase] = useState(null);
  const [regionData, setRegionData] = useState({});
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [originalLocations, setOriginalLocations] = useState({});
  const [zoom, setZoom] = useState(1);
  const [map, setMap] = useState(null);
  const [zoomedIn, setZoomedIn] = useState(false);
  const [snapToCenter, setSnapToCenter] = useState(true);
  const [dataOffset, setDataOffset] = useState(0);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [isOverlayRendered, setIsOverlayRendered] = useState(false);
  const [selectedVictim, setSelectedVictim] = useState(null);
  const [overlayData, setOverlayData] = useState({});
  const [legendOpen, setLegendOpen] = useState(false);
  const { randomLocation } = useMapContext();

  const groupByRegion = useCallback((data, zoom) => {
    const groupedData = Object.values(data).reduce((result, info) => {
      const { geoLocation, victimNames } = info;
      const [lat, lng] = geoLocation;
      let regionKey;

      if (zoom < 2) {
        regionKey = `${lat},${lng}`;
      } else if (zoom < 7) {
        const regionLat = Math.floor(lat);
        const regionLng = Math.floor(lng);
        regionKey = `${regionLat},${regionLng}`;
      } else {
        const regionLat = Math.floor(lat * 10) / 10;
        const regionLng = Math.floor(lng * 10) / 10;
        regionKey = `${regionLat},${regionLng}`;
      }

      if (!result[regionKey]) {
        result[regionKey] = { victimNames: [], geoLocations: [] };
      }

      result[regionKey].victimNames.push(...victimNames);
      result[regionKey].geoLocations.push(geoLocation);

      return result;
    }, {});

    const originalLocationsData = Object.keys(groupedData).reduce((result, regionKey) => {
      result[regionKey] = groupedData[regionKey].geoLocations;
      return result;
    }, {});

    setOriginalLocations(originalLocationsData);

    return groupedData;
  }, []);

  const fetchDataAndUpdateState = useCallback(async () => {
    const data = await fetchData(dataOffset, DATA_CHUNK_SIZE);
    if (data) {
      setDataBase((prevDataBase) => ({ ...prevDataBase, ...data }));
      setDataOffset((prevOffset) => prevOffset + DATA_CHUNK_SIZE);
    }
  }, [dataOffset]);

  useEffect(() => {
    fetchDataAndUpdateState();
  }, [fetchDataAndUpdateState]);

  useEffect(() => {
    if (!overlayData) return;

    const newIncidentCount = Object.keys(overlayData).length;
    const newVictimCount = Object.values(overlayData).reduce(
      (count, info) => count + info.victimNames.length,
      0
    );

    setIncidentCount(newIncidentCount);
    setVictimCount(newVictimCount);
    setRegionData(groupByRegion(overlayData, zoom));
  }, [overlayData, groupByRegion, zoom]);

  const processedRegionData = useMemo(() => {
    if (!regionData) return [];

    return Object.keys(regionData).map((regionKey) => {
      const data = regionData[regionKey];
      const uniqueVictimNames = [...new Set(data.victimNames)];
      const originalLocationsForRegion = originalLocations[regionKey];
      return {
        regionKey,
        originalLocationsForRegion,
        uniqueVictimNames,
        victimNames: data.victimNames,
        position: {
          lat: originalLocationsForRegion[0][0],
          lng: originalLocationsForRegion[0][1],
        },
      };
    });
  }, [regionData, originalLocations]);

  const processedDataBase = useMemo(() => {
    if (!dataBase) return [];

    return Object.entries(dataBase).map(([id, info]) => ({
      id,
      geoLocation: {
        lat: info.geoLocation[0],
        lng: info.geoLocation[1],
      },
      victimNames: info.victimNames.join(", "),
    }));
  }, [dataBase]);

  const toggleLegend = useCallback((e) => {
    e.stopPropagation();
    if (aboutOpen) {
      setAboutOpen(false);
    }
    setLegendOpen((prev) => !prev);
  }, [aboutOpen]);

  const toggleAbout = useCallback(() => {
    if (legendOpen) {
      setLegendOpen(false);
    }
    setAboutOpen((prev) => !prev);
  }, [legendOpen]);

  const closeAbout = useCallback(() => {
    setAboutOpen(false);
  }, []);

  const closeLegend = useCallback(() => {
    setLegendOpen(false);
  }, []);

  useEffect(() => {
    const loadData = async () => {
      if (dataBase) return;

      const data = await fetchData(dataOffset, DATA_CHUNK_SIZE);

      if (data) {
        const sortedData = Object.entries(data).sort();
        const reducedData = Object.fromEntries(sortedData);

        if (data && data !== dataBase) {
          const filteredData = {};
          if (map) {
            for (const [id, info] of Object.entries(reducedData)) {
              const latLng = new window.google.maps.LatLng(
                info.geoLocation[0],
                info.geoLocation[1]
              );
              if (map?.getBounds()?.contains(latLng)) {
                filteredData[id] = info;
              }
            }
          } else {
            Object.assign(filteredData, reducedData);
          }

          setOverlayData(filteredData);
          setDataBase(data);

          setDataOffset((prevOffset) => prevOffset + DATA_CHUNK_SIZE);
        }
      }
    };

    const handleZoomChange = () => {
      setZoom(map.getZoom());
    };

    const handleClick = (e) => {
      const legend = document.querySelector(".legend");
      if (legend && !legend.contains(e.target)) {
        closeLegend();
      }
    };

    loadData();

    if (map) {
      map.addListener("zoom_changed", handleZoomChange);
      setZoom(map.getZoom());
    }

    window.addEventListener("click", handleClick, { passive: true });

    return () => {
      if (map) {
        map.removeListener("zoom_changed", handleZoomChange);
      }
      window.removeEventListener("click", handleClick);
    };
  }, [closeLegend, dataBase, dataOffset, map]);

  useEffect(() => {
    setIncidentCount(Object.keys(overlayData).length);
    setVictimCount(
      Object.values(overlayData).reduce(
        (count, info) => count + info.victimNames.length,
        0
      )
    );
    setRegionData(groupByRegion(overlayData, zoom));
  }, [overlayData, groupByRegion, zoom]);

  useEffect(() => {
    if (zoom === 12) {
      setZoomedIn(true);
    } else {
      setZoomedIn(false);
    }
  }, [zoom]);

  const onMapLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
    mapInstance.addListener("zoom_changed", () => {
      setZoom(mapInstance.getZoom());
    });
    setZoom(mapInstance.getZoom());
  }, []);

  useGoogleMapListener(map, "center_changed", () => {
    if (snapToCenter) {
      setIsOverlayRendered(false);
    }
  });
  useGoogleMapListener(map, "idle", () => setIsOverlayRendered(true));
  useGoogleMapListener(map, "dragstart", () => {
    dragDebounced(setSnapToCenter);
  });
  useGoogleMapListener(map, "tilesloaded", () => setIsMapLoaded(true));
  useGoogleMapListener(map, "zoom_changed", () => {
    setZoom(map.getZoom());
    setSelectedVictim(null);
  });

  const handleZoom = (delta) => {
    if (map) {
      const currentZoom = map.getZoom();
      map.setZoom(currentZoom + delta);
    }
  };

  const handleZoomIn = () => {
    handleZoom(1);
  };

  const handleZoomOut = () => {
    handleZoom(-1);
  };

  useEffect(() => {
    if (selectedVictim && map) {
      if (
        shouldRenderOverlay(map, zoom, isOverlayRendered) &&
        !isOverlayRendered
      ) {
        setIsOverlayRendered(null);
        map.panTo(selectedVictim);
        map.setZoom(15);
      } else {
        map.panTo(selectedVictim);
        map.setZoom(15);
        setIsOverlayRendered(true);
      }
      setSnapToCenter(false);
    }
  }, [selectedVictim, map, zoom, isOverlayRendered]);

  useEffect(() => {
    if (zoom < MIN_ZOOM_LEVEL || selectedVictim) {
      setIsOverlayRendered(false);
    } else {
      setIsOverlayRendered(true);
    }
  }, [zoom, selectedVictim]);

  const options = useMemo(
    () => ({
      mapTypeId: "satellite",
      gestureHandling: "cooperative", // Enhances mobile touch gestures
      preserveViewport: true, // Caches map tiles for faster load on revisit
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      disableDefaultUI: true,
      restriction: {
        latLngBounds: bounds,
        strictBounds: true,
      },
      minZoom: MIN_ZOOM_LEVEL,
      maxZoom: 15,
      zoom: zoom,
    }),
    [zoom]
  );

  const handleLegendClick = useCallback(
    (event) => {
      handleLegendClick(
        event,
        isMapLoaded,
        dataBase,
        map,
        setSelectedVictim,
        setSnapToCenter
      );
    },
    [map, isMapLoaded, dataBase]
  );

  const debouncedHandleLegendClick = useMemo(() => {
    return debounce(
      (event) => {
        handleLegendClick(
          event,
          isMapLoaded,
          dataBase,
          map,
          setSelectedVictim,
          setSnapToCenter
        );
      },
      300
    );
  }, [map, isMapLoaded, dataBase, handleLegendClick]);

  const smoothZoom = useCallback((targetLocation, initialTargetZoom, finalTargetZoom, finalZoom) => {
    if (map) {
        const currentZoom = map.getZoom();
        const intermediateZoom = Math.max(0, currentZoom - Math.abs(currentZoom - initialTargetZoom) / 2); // Dynamically calculate intermediate zoom level
        
        let step;
        let isFinalZoom = false;

        if (currentZoom === initialTargetZoom) {
            // If already at initialTargetZoom, proceed to final zoom
            step = (finalTargetZoom - initialTargetZoom) / 50; // Increase steps for smoother transition
            isFinalZoom = true;
        } else {
            step = (initialTargetZoom - intermediateZoom) / 50; // Increase steps for smoother transition
        }

        const currentPosition = map.getCenter();
        const latDiff = (targetLocation.lat - currentPosition.lat()) / 50;
        const lngDiff = (targetLocation.lng - currentPosition.lng()) / 50;

        // Immediately set to intermediateZoom level to zoom out.
        if (!isFinalZoom) {
            map.setZoom(intermediateZoom);
        }

        function innerSmoothZoom(level, targetZoom) {
            if (Math.abs(targetZoom - level) <= Math.abs(step)) {
                map.setZoom(targetZoom);
                if (targetZoom === initialTargetZoom && !isFinalZoom) {
                    setTimeout(() => {
                        smoothZoom(targetLocation, initialTargetZoom, finalTargetZoom);
                    }, 200); // Reduced delay before zooming further
                }
            } else {
                const newLat = map.getCenter().lat() + latDiff;
                const newLng = map.getCenter().lng() + lngDiff;

                map.setCenter({ lat: newLat, lng: newLng });
                map.setZoom(level);
                setTimeout(() => {
                    innerSmoothZoom(level + step, targetZoom);
                }, 30); // Reduced delay between zoom steps for faster transition
            }
        }

        function smoothPanAndZoom() {
            if (isFinalZoom) {
                innerSmoothZoom(initialTargetZoom, finalTargetZoom);
            } else {
                innerSmoothZoom(intermediateZoom, initialTargetZoom);
            }
        }

        setTimeout(smoothPanAndZoom, 50); // Reduced initial delay for faster start
    }
  }, [map]);

  useEffect(() => {
    if (map && randomLocation) {
        const currentZoom = map.getZoom();
        const targetZoom = 10;

        // Call generalized smoothZoom function
        smoothZoom(randomLocation, currentZoom, targetZoom);
    }
  }, [map, randomLocation, smoothZoom]);


  const MemoizedOverlay = React.memo(OverlayView, (prevProps, nextProps) => {
    return (
      prevProps.position.lat === nextProps.position.lat &&
      prevProps.position.lng === nextProps.position.lng
    );
  });

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <LoadScript
        googleMapsApiKey="AIzaSyAtHvdtieYWcZQjF0t0cMgjpDTtSgK4KpY"
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          onLoad={onMapLoad}
          options={options}
        >
          <Legend
            incidentCount={incidentCount}
            victimCount={victimCount}
            processedDataBase={processedDataBase}
            processedRegionData={processedRegionData}
            zoom={zoom}
            isMapLoaded={isMapLoaded}
            dataBase={dataBase}
            map={map}
            setSelectedVictim={setSelectedVictim}
            toggleLegend={toggleLegend}
            toggleAbout={toggleAbout}
            legendOpen={legendOpen}
            aboutOpen={aboutOpen}
            debouncedHandleLegendClick={debouncedHandleLegendClick}
            isOverlayRendered={isOverlayRendered}
            selectedVictim={selectedVictim}
            closeLegend={closeLegend}
            closeAbout={closeAbout}
            handleZoomIn={handleZoomIn}
            handleZoomOut={handleZoomOut}
            smoothZoom={smoothZoom}
          />

          {processedRegionData.map(
            function ({
              originalLocationsForRegion,
              uniqueVictimNames,
              victimNames,
              position,
            },
            index) {
              return (
                <MemoizedOverlay
                  key={index}
                  position={position}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  shouldRender={() =>
                    shouldRenderOverlay(map, zoom, isOverlayRendered, position)
                  }
                >
                  <div className="OverlayContainer">
                    <div className="App">
                    {clusterNames(uniqueVictimNames, zoom, victimNames).length > 0 && (
                        <LocationNames
                          names={clusterNames(
                            uniqueVictimNames,
                            zoom,
                            victimNames
                          )}
                          maxVisibleNames={MAX_VISIBLE_NAMES}
                          zoom={zoom}
                          selectedVictim={selectedVictim}
                          lat={originalLocationsForRegion[0][0]}
                          lng={originalLocationsForRegion[0][1]}
                          zoomedIn={zoomedIn}
                        />
                      )}
                    </div>
                  </div>
                </MemoizedOverlay>
              );
            }
          )}
        </GoogleMap>
      </LoadScript>
      <div className="footer">
      <p>Remember the Rain | A non-profit initiative developed by <a href="https://www.studiocoat.nl" rel="noreferrer" target="_blank">Studio Coat</a></p>
      </div>
    </React.Suspense>
  );
}

export default Cloudmap;


