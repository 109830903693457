export const shouldRenderOverlay = (map, zoom, isOverlayRendered, position) => map && zoom >= 7 && !isOverlayRendered && isInBounds(map, position);
function isInBounds(map, position) {
  if (!map) {
    console.error("map is undefined");
    return false;
  }
  const bounds = map.getBounds();
  if (!bounds) {
    console.error("bounds is undefined");
    return false;
  }
  return bounds.contains(position);
}
