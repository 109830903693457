export const MAX_VISIBLE_NAMES = 5;

export const clusterNames = (victimNames, zoom, allNames) => {
  if (zoom > 6) {
    return allNames;
  } else if (zoom < 4) {
    return [];
  } else if (zoom < 7) {
    const namesToShow = Math.min(MAX_VISIBLE_NAMES, allNames.length);
    return victimNames.slice(0, namesToShow);
  } else {
    const stepSize = 20 / (Math.pow(2, zoom - 1) * 2);
    const startIndex = Math.floor(stepSize * allNames.length);
    const endIndex = Math.floor((22 * stepSize) * allNames.length);
    const clusterNames = victimNames.slice(startIndex, endIndex);
    return clusterNames.slice(0, MAX_VISIBLE_NAMES);
  }
};
