import React, { useState, useEffect } from "react";
import "./styles/Intro.css";
import { useMapContext } from "./elements/mapContext";

function Overlay() {
  const [isVisible, setIsVisible] = useState(true);
  const [isClicked, setIsClicked] = useState(false);
  const [locations, setLocations] = useState([]);
  const { setRandomLocation } = useMapContext();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    // Set fixed locations for New York and Los Angeles
    const cities = [
      {
        "city": "Sacramento, California",
        "lat": 38.5816,
        "lng": -121.4944
      },
      {
        "city": "New York, New York City",
        "lat": 40.7128,
        "lng": -74.0060
      },
      {
        "city": "Denver, Colorado",
        "lat": 39.7392,
        "lng": -104.9903
      },
      // {
      //   "city": "Frankfort, Kentucky",
      //   "lat": 38.1867,
      //   "lng": -84.8754
      // },
      {
        "city": "Trenton, New Jersey",
        "lat": 40.2206,
        "lng": -74.7597
      }
    ];
    

    // Populate locations array with fixed locations
    setLocations(cities);

    // Add an event listener to check for window resize and adjust platform detection accordingly
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      // Cleanup the event listener on unmount
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleButtonClick = () => {
    setIsClicked(true);
    setIsVisible(false);

    // Randomly select from the fixed locations array between New York and Los Angeles
    const randomIndex = Math.floor(Math.random() * locations.length);
    const randomLocationFromData = locations[randomIndex];
    setRandomLocation(randomLocationFromData);
  };

  const handleDivClick = () => {
    setIsVisible(true);
  };

  return (
    <div className="flex-container" role="main">
        <div
            onClick={handleDivClick}
            className={`overlay ${isVisible ? "visible" : "hidden"}`}
            role="presentation" /* This indicates the div is for presentation only */
        />
        <div className={`flex-item ${isClicked ? "smaller-item" : ""}`} role="document">
            <h1 className={isClicked ? "smaller" : ""}>Remember the rain</h1>
            {!isClicked && (
                <React.Fragment>
            <h2 className="text">
              Remember the Rain is an interactive project that honors the victims of gun violence in America. 
              The project features an interactive map of mass shooting locations in America, 
              each marked by a "cloud" of names representing the victims. 
              These clouds are a powerful visual reminder of the human toll of gun violence in America and the devastating impact on the victims and their families. 
              <br /><br />
              The project seeks to create a more informed and empathetic society by creating a more personal connection to these tragedies. 
              Through this work, the goal is to bring more awareness to the victims and families of mass shootings in America and to help create a future where these events are no longer common.
            </h2>
            <button 
                        onClick={handleButtonClick} 
                        className="interactive-button"
                        aria-label={isMobile ? "Swipe & Pinch to Explore the Clouds" : "Drag & Zoom to Explore the Clouds"}
                    > 
                        {isMobile ? 
                            <>
                                <i className="fa fa-hand-o-up" aria-hidden="true"></i> Swipe & Pinch
                            </> : 
                            <>
                                <i className="fa fa-mouse-pointer" aria-hidden="true"></i> Drag & Zoom
                            </>
                        }
                        <span className="cloud-instruction">to Explore the Clouds</span>
                    </button>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default Overlay;
