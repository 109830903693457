import { useEffect } from "react";

function useGoogleMapListener(map, eventName, handler) {
  useEffect(() => {
    if (map) {
      const listener = window.google.maps.event.addListener(map, eventName, handler);
      return () => {
        window.google.maps.event.removeListener(listener);
      };
    }
  }, [map, eventName, handler]);
}

export default useGoogleMapListener;
