import { calculateDistance } from "./calculateDistance";

const Legend = ({
  incidentCount,
  victimCount,
  processedDataBase,
  toggleLegend,
  toggleAbout,
  legendOpen,
  aboutOpen,
  debouncedHandleLegendClick,
  handleZoomIn,
  handleZoomOut,
  smoothZoom,
  map
}) => {

  const onNameClick = (location) => {
    const currentPosition = map.getCenter();
    const distance = calculateDistance({lat: currentPosition.lat(), lng: currentPosition.lng()}, location);

    const initialZoom = distance > 100 ? 6 : 10; // Define SOME_THRESHOLD
    smoothZoom(location, initialZoom, 12); 
};

  return (
    <div className={`legend-container ${legendOpen || aboutOpen ? "open" : ""}`}>
      <div className={`tabs-container ${legendOpen || aboutOpen ? "open" : ""}`}>
        <div className={`tab ${legendOpen ? "active" : ""}`} onClick={toggleLegend}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 18 18"
          >
            <path d="M2.93912168,7.71519441 C2.32925394,7.71519441 1.83695105,8.20749729 1.83695105,8.81736504 C1.83695105,9.42723278 2.32925394,9.91953568 2.93912168,9.91953568 C3.54898943,9.91953568 4.04129231,9.42723278 4.04129231,8.81736504 C4.04129231,8.20749729 3.54898943,7.71519441 2.93912168,7.71519441 L2.93912168,7.71519441 L2.93912168,7.71519441 Z M2.93912168,3.3065119 C2.32925394,3.3065119 1.83695105,3.79881478 1.83695105,4.40868252 C1.83695105,5.01855027 2.32925394,5.51085315 2.93912168,5.51085315 C3.54898943,5.51085315 4.04129231,5.01855027 4.04129231,4.40868252 C4.04129231,3.79881478 3.54898943,3.3065119 2.93912168,3.3065119 L2.93912168,3.3065119 L2.93912168,3.3065119 Z M2.93912168,12.2487896 C2.39538417,12.2487896 1.96186372,12.6896579 1.96186372,13.2260475 C1.96186372,13.7624372 2.40273197,14.2033055 2.93912168,14.2033055 C3.47551139,14.2033055 3.91637964,13.7624372 3.91637964,13.2260475 C3.91637964,12.6896579 3.48285919,12.2487896 2.93912168,12.2487896 L2.93912168,12.2487896 L2.93912168,12.2487896 Z M5.14346294,13.960828 L15.4303888,13.960828 L15.4303888,12.4912672 L5.14346294,12.4912672 L5.14346294,13.960828 L5.14346294,13.960828 L5.14346294,13.960828 Z M5.14346294,9.55214546 L15.4303888,9.55214546 L15.4303888,8.08258462 L5.14346294,8.08258462 L5.14346294,9.55214546 L5.14346294,9.55214546 L5.14346294,9.55214546 Z M5.14346294,3.6739021 L5.14346294,5.14346294 L15.4303888,5.14346294 L15.4303888,3.6739021 L5.14346294,3.6739021 L5.14346294,3.6739021 L5.14346294,3.6739021 Z" fill="#ffffff"/>
          </svg>
        </div>
        <div className={`tab ${aboutOpen ? "active" : ""}`} onClick={toggleAbout}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 500 500"
      >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="about-white" fill="#ffffff" transform="translate(42.666667, 42.666667)">
              <path d="M213.333333,3.55271368e-14 C95.51296,3.55271368e-14 3.55271368e-14,95.51168 3.55271368e-14,213.333333 C3.55271368e-14,331.153707 95.51296,426.666667 213.333333,426.666667 C331.154987,426.666667 426.666667,331.153707 426.666667,213.333333 C426.666667,95.51168 331.154987,3.55271368e-14 213.333333,3.55271368e-14 Z M213.333333,384 C119.227947,384 42.6666667,307.43872 42.6666667,213.333333 C42.6666667,119.227947 119.227947,42.6666667 213.333333,42.6666667 C307.44,42.6666667 384,119.227947 384,213.333333 C384,307.43872 307.44,384 213.333333,384 Z M240.04672,128 C240.04672,143.46752 228.785067,154.666667 213.55008,154.666667 C197.698773,154.666667 186.713387,143.46752 186.713387,127.704107 C186.713387,112.5536 197.99616,101.333333 213.55008,101.333333 C228.785067,101.333333 240.04672,112.5536 240.04672,128 Z M192.04672,192 L234.713387,192 L234.713387,320 L192.04672,320 L192.04672,192 Z" id="Shape">
             </path>
          </g>
      </g>
      </svg>
      </div>
      <div className="zoomIn" onClick={handleZoomIn}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-13h-2v4H7v2h4v4h2v-4h4v-2h-4V7z" fill="#ffffff"/>
        </svg>
      </div>
      <div className="zoomOut" onClick={handleZoomOut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM7 11h10v2H7z" fill="#ffffff"/>
        </svg>
      </div>
    </div>
    {legendOpen && (
      <div className="legend open">
        <div className="legend-header" onClick={debouncedHandleLegendClick}>
          <div className="legend-title-container">
            <h3 className="legend-title">Mass shootings of 2023</h3>
            <p className="legend-date-range">January 1st - April 11th</p>
            <p className="legend-stats">
              <span className="legend-incidents">Shootings: {incidentCount}</span> |&nbsp;
              <span className="legend-victims">Victims: {victimCount}</span>
            </p>
          </div>
          <button className="close-button" onClick={toggleLegend}>
            X
          </button>
        </div>
        <div className="legend-scrollable">
          <ul>
          {processedDataBase.map(({ id, geoLocation, victimNames }) => (
              <li key={id}>
                <div onClick={() => onNameClick(geoLocation)}>
                  <span className="victim-name" data-victim-id={id}>
                    {victimNames}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )}
    {aboutOpen && (
      <div className="legend open">
        <div className="legend-header" onClick={debouncedHandleLegendClick}>
          <h3 className="legend-title">About</h3>
          <button className="close-button" onClick={(e) => {
            e.stopPropagation();
            toggleAbout();
          }}>
            X 
          </button>

        </div>
        <div className="legend-content">
          <p>Honoring Victims of Gun Violence in America<br/><br/> Remember the Rain is an interactive project that brings attention to the devastating impact of mass shootings in America. Using innovative mapping technology, personal storytelling, and emotional impact, Remember the Rain honors the victims and their families. <br/><br/>The project features an interactive map of mass shooting locations in America, each marked by a "cloud" of names representing the victims. These clouds serve as a powerful visual reminder of the human toll of gun violence in America, and the devastating impact on the victims and their families. <br/><br/>The name "Remember the Rain" was chosen to evoke the idea of a dark cloud hanging over these tragic events and the communities affected by them. The rain symbolizes the outpouring of grief and mourning for the victims, as well as the hope for a future free from mass shootings. <br/><br/>Remember the Rain seeks to create a more informed and empathetic society by creating a more personal connection to these tragedies. Through this project, we hope to bring more awareness to the victims and families of mass shootings in America, and to help create a future where these events are no longer a common occurrence. </p>
        </div>
      </div>
    )}
  </div>
  );
};

export default Legend;
